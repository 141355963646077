import React, { useState } from "react"
import { Banner } from "@shopify/polaris"
import styled from "styled-components"
import { navigate } from "gatsby"
const LearnMoreWrapper = styled.div`
  margin-top: 5px;
  a {
    cursor: pointer;
    text-decoration: underline;
  }
`
const CustomizationAnnounceBanner = (props) => {
  const { setSelectedTabIndex } = props
  const [bannerActive, setBannerActive] = useState(true)
  return (
    <div style={{ marginBottom: "15px", display: "inline-block" }}>
      {bannerActive && (
        <Banner
          title="NEW: Grow conversion with checkout customizations"
          status="success"
          onDismiss={() => {
            setBannerActive(false)
          }}
        >
          <p>
            Customize the checkout with Content Blocks, Field Blocks, Address
            blockers and Shipping and Payment customizations. See the new
            customizations tab?
          </p>
          <LearnMoreWrapper
            onClick={() => {
                setSelectedTabIndex(2)
            }}
          >
            <a>Learn more</a>
          </LearnMoreWrapper>
        </Banner>
      )}
    </div>
  )
}

export default CustomizationAnnounceBanner
